<template>
  <VTradeProcedure
    :url-action-query="`${EXPAND_LOT}&search[Active]=archive`"
    :is-chosen-icon="false"
    class-toggle-display="my-trade__toggle-display--admin"
  />
</template>
<script>
import VTradeProcedure from '@/components/views/TradeProcedure/VTradeProcedure'
import { EXPAND_LOT } from '@/utils/consts'

export default {
  setup () {
    return {
      EXPAND_LOT
    }
  },
  components: { VTradeProcedure }
}
</script>
